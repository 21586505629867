import smoothScroll from "smooth-scroll";
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import Swiper from 'swiper/bundle';
// import Splide from 'alpine-splide';
// import { initial } from "lodash";
// import AlpineInstance from "alpinejs";
// import MicroModal from "micromodal";

window.Alpine = Alpine;
Alpine.plugin(intersect);

const scrollOption = {
  speed: 320,
  speedAsDuration: true,
  topOnEmptyHash: true,
  header: "[data-scroll-offset]",
};

// MicroModal Options
const modalOption = {
  debugMode: true,
  openTrigger: "data-modal-trigger",
  closeTrigger: "data-modal-close",
  openClass: "is-open",
  disableScroll: true,
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
};

const mvSlideOption = {
  loopAdditionalSlides: 1,
  slidesPerView: "auto",
  loop: true,
  speed: 8000,
  freeMode: {
    enable: true,
    momentum: false,
  },
  autoplay: {
    delay: 0,
    disableOnInteraction: false
  },
  // breakpoints: {
  //   768: {
  //     slidesPerView: "auto"
  //   }
  // }
}
const mvslider = new Swiper('.mv-slider', mvSlideOption);

const svSlideOption = {
  centeredSlides: true,
  slidesPerView: 1.75,
  spaceBetween: 40,
  loop: true,
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: ".sv-slider-navigation > .pagination"
  },
  navigation: {
    nextEl: ".sv-slider-navigation > .button-next",
    prevEl: ".sv-slider-navigation > .button-prev"
  },
  breakpoints: {
    768: {
      slidesPerView: 2.7,
      spaceBetween: 70
    },
    992: {
      slidesPerView: 3.6,
      spaceBetween: 60
    },
    1200: {
      slidesPerView: 4.8,
      spaceBetween: 50
    }
  }
}
const svslider = new Swiper('.sv-slider', svSlideOption);

// Switch ViewPort
// 折り畳みやモバイル端末の分割表示の際に横幅(vw)が360px以下の物に対して
// viewport width=360に切り替える処理
function switchViewport(value) {
  let viewport = document.querySelector('meta[name="viewport"]');
  if (viewport.getAttribute("content") !== value) {
    viewport.setAttribute("content", value);
  }
}
const resizeObserver = new ResizeObserver((entries) => {
  entries.forEach((entry) => {
    let contentWidth = document.documentElement.clientWidth;
    if (contentWidth > 360) {
      switchViewport("width=device-width,initial-scale=1");
    } else {
      switchViewport("width=360");
    }
  });
});

// HTMLのinclude
function _include(target, position, data) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', data);
  xhr.onerror = function () {
    console.error(`include error: ${target}`);
  };
  xhr.send();
  xhr.onload = function () {
    target.insertAdjacentHTML(position, xhr.response);
    if (typeof _includeAfterInit === 'function') {
      _includeAfterRun(target);
    }
  };
}

// トップからスクロール時、ヘッダをコンパクトに
// const header = document.querySelector('.header-container');
// const observerHeader = new IntersectionObserver((entries) => {
//   for (const e of entries) {
//     if (e.isIntersecting) {
//       header.classList.remove('compact');
//     } else {
//       header.classList.add('compact');
//     }
//   }
// });
// observerHeader.observe(document.querySelector('.header-container'));

const accordion = document.querySelectorAll('.c-accordion-heading');
[...accordion].map((element) => {
  element.addEventListener('click', (event) => {
    const modt = window.matchMedia("(min-width: 48em)").matches ? 'desktop' : 'mobile';
    if (modt === 'mobile') {
      element.classList.toggle('is-expand');
      event.preventDefault;
    }
  })
});

// alpineの発動にあわせる
document.addEventListener('alpine:init', () => {
  const params = Alpine.reactive({
    modt: window.matchMedia("(min-width: 48em)").matches ? 'desktop' : 'mobile'
  });
  const dataList = document.querySelectorAll('div[data-include$=".html"]');
  Array.prototype.map.call(dataList, function (obj) {
    const position = obj.dataset.includePosition || 'afterbegin';
    return _include(obj, position, obj.dataset.include);
  });
  resizeObserver.observe(document.querySelector("html"));

  // Desktopの時はtrueを返す事でメニューを表示, Mobileの時はfalseで初期非表示
  Alpine.data('navlist', () => ({
    // open: params.modt == 'desktop' ? true : false,
    open: false,
    toggle () {
      this.open = !this.open;
    }
  }))
})

Alpine.start()
